var searchKeys = [{
  key: "videoid",
  label: "视频ID",
  placeholder: "视频ID",
  required: false,
  rules: [],
  input: true,
  trim: true
}, {
  key: "metadataurl",
  label: "Metadata链接",
  placeholder: "Metadata链接",
  required: false,
  rules: [],
  input: true,
  trim: true
}, {
  key: "applyusername",
  label: "申请用户昵称",
  placeholder: "申请用户昵称",
  required: false,
  rules: [],
  input: true,
  trim: true
}, {
  key: "author",
  label: "视频作者",
  placeholder: "视频作者",
  required: false,
  rules: [],
  input: true,
  trim: true
}, {
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
export { searchKeys };