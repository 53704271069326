var columns = [{
  title: "视频ID",
  dataIndex: "videoid",
  key: "videoid",
  width: "10%",
  scopedSlots: {
    customRender: "videoid"
  }
}, {
  title: "视频标题",
  dataIndex: "videotitle",
  key: "videotitle",
  width: "15%",
  scopedSlots: {
    customRender: "videotitle"
  }
}, {
  title: "Metadata链接",
  dataIndex: "metadataurl",
  key: "metadataurl",
  width: "15%",
  scopedSlots: {
    customRender: "metadataurl"
  }
}, {
  title: "申请用户昵称",
  dataIndex: "applyusername",
  key: "applyusername",
  width: "10%",
  scopedSlots: {
    customRender: "applyusername"
  }
}, {
  title: "视频创作者昵称",
  dataIndex: "authorname",
  key: "authorname",
  width: "10%",
  scopedSlots: {
    customRender: "authorname"
  }
}, {
  title: "消费CBK",
  dataIndex: "amount",
  key: "amount",
  width: "10%",
  scopedSlots: {
    customRender: "amount"
  }
}, {
  title: '状态',
  dataIndex: 'uploaddecentralizedresult',
  key: "uploaddecentralizedresult",
  width: "10%",
  scopedSlots: {
    customRender: "uploaddecentralizedresult"
  }
}, {
  title: '申请时间',
  dataIndex: 'createtimedate',
  key: "createtimedate",
  width: "10%",
  scopedSlots: {
    customRender: "createtimedate"
  }
}, {
  title: '完成时间',
  dataIndex: 'donedate',
  key: "donedate",
  width: "10%",
  scopedSlots: {
    customRender: "donedate"
  }
}];
export { columns };